html body {
	height: 100%;
	font-family: 'Roboto', sans-serif;
	background-color: #F0F0F0;
	padding: 0;
	margin: auto;
}

img.logo {
	width: 100%;
    max-width: 360px;
    height: auto;
}

button.button.primary, button.button.primary:hover, button.button.primary:disabled {
	border: 1px solid #194fa1;
	background-color: #194fa1;
}

button.button.secondary, button.button.secondary:hover {
	border: 1px solid #194fa1;
	color: #194fa1;
}

a {
	color: #194fa1 !important;
}

.form-group input:hover, .form-group input:focus {
	border-color: #194fa1;
}

.checkbox-container .checkbox-input:checked+label.label.checkbox-label {
	background-color: #194fa1;
	border: 1px solid #194fa1;
}
