img.client-logo {
    height: 25px;
}

hr.separator {
    border-width: 0px;
    height: 1px;
    background-color: #194fa1;
    margin-bottom: 1rem;
    margin-top: 1rem;
}